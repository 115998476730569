import './style.css';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';

Alpine.plugin(intersect)

/* dev only */

window.addEventListener('beforeunload', () => {
  localStorage.setItem('scrollPosition', window.scrollY);
});

// Restore scroll position after the page loads
window.addEventListener('load', () => {
  const scrollPosition = localStorage.getItem('scrollPosition');
  if (scrollPosition !== null) {
    window.scrollTo({top: parseInt(scrollPosition, 10), left: 0, behavior:"smooth"});
    localStorage.removeItem('scrollPosition'); // Clean up
  }
});

/* dev only */

window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', async () => {
  //for dynamic, per page modules
  //let module = document.getElementById("content").dataset.module; 
  //if (module) {
  //  const modules = import.meta.glob('./*.js');
  //  
  //  const loadModule = async (moduleName) => {
  //    if (modules[`./${moduleName}.js`]) {
  //      return await modules[`./${moduleName}.js`]();
  //    } else {
  //      console.error(`Module './${moduleName}.js' not found.`);
  //    }
  //  };
  //
  //  // Load and register the Alpine.js module
  //  const importedModule = await loadModule(module);
  //  if (importedModule) {
  //    Alpine.data('contact', importedModule.default);
  //  }
  //}

  Alpine.start();
});
